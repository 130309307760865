import { Injectable } from '@angular/core';
import { ApiResponse } from '@core/api';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { DialogContentComponent } from './dialog';
import { DialogCloseResult, DialogContentData, DialogContentType } from './dialog.model';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(
    private modalService: NgbModal
    ) { }

  public ShowMessage(data: DialogContentData): Observable<DialogCloseResult> {
    const dialogRef = this.modalService.open(DialogContentComponent, {
      backdrop: false,
    });
    dialogRef.componentInstance.data = data;

    return dialogRef.closed;
  }

  public ShowConfirm(message: string, title = '') {
    const data: DialogContentData = {
      title: title,
      message: message,
      confirmText: $localize`:@@Message.Yes:Yes`,
      cancelText: $localize`:@@Message.No:No`,
      type: DialogContentType.CONFIRM,
    };

    return this.ShowMessage(data);
  }

  public ShowWarn(message: string, title = '') {
    const data: DialogContentData = {
      title: title,
      message: message,
      confirmText: $localize`:@@OK:OK`,
      cancelText: '',
      type: DialogContentType.WARN,
    };

    return this.ShowMessage(data);
  }

  public ShowWarnRequired() {
    const data: DialogContentData = {
      title: '',
      message: $localize`:@@Message.RequiredFail:Vui lòng nhập các trường bắt buộc`,
      confirmText: $localize`:@@OK:OK`,
      cancelText: '',
      type: DialogContentType.WARN,
    };

    return this.ShowMessage(data);
  }

  public ShowInsertFailError(response: ApiResponse<unknown>, title = ''): Observable<DialogCloseResult> {
    const data: DialogContentData = {
      title: title,
      message: $localize`:@@Message.InsertFail:Thêm mới thất bại.` + (response.Description ?? response.Status),
      confirmText: $localize`:@@OK:OK`,
      cancelText: '',
      type: DialogContentType.ERROR,
    };

    return this.ShowMessage(data);
  }

  public ShowUpdateFailError(response: ApiResponse<unknown>, title = ''): Observable<DialogCloseResult> {
    const data: DialogContentData = {
      title: title,
      message: $localize`:@@Message.UpdateFail:Cập nhật thất bại. ` + (response.Description ?? response.Status),
      confirmText: $localize`:@@OK:OK`,
      cancelText: '',
      type: DialogContentType.ERROR,
    };

    return this.ShowMessage(data);
  }

  public ShowFailureActionError(response: ApiResponse<unknown>, title = ''): Observable<DialogCloseResult> {
    const data: DialogContentData = {
      title: title,
      message: $localize`:@@Message.FailureAction:Cập nhật thất bại. ` + (response.Description ?? response.Status),
      confirmText: $localize`:@@OK:OK`,
      cancelText: '',
      type: DialogContentType.ERROR,
    };

    return this.ShowMessage(data);
  }

  public ShowError(message: string, title = ''): Observable<DialogCloseResult> {
    const data: DialogContentData = {
      title: title,
      message: message,
      confirmText: $localize`:@@OK:OK`,
      cancelText: '',
      type: DialogContentType.ERROR,
    };

    return this.ShowMessage(data);
  }

  public ShowErrorResponse(response: ApiResponse<unknown>, message?: string, title = ''): Observable<DialogCloseResult> {
    const errMsg = response.Description ?? response.Status;

    const data: DialogContentData = {
      title: title,
      message: errMsg,
      confirmText: $localize`:@@OK:OK`,
      cancelText: '',
      type: DialogContentType.ERROR,
    };

    return this.ShowMessage(data);
  }

  public ShowInfo(message: string, title = '') {
    const data: DialogContentData = {
      title: title,
      message: message,
      confirmText: $localize`:@@OK:OK`,
      cancelText: '',
      type: DialogContentType.INFO,
    };

    return this.ShowMessage(data);
  }

  public ShowSuccess(message: string) {
    const data: DialogContentData = {
      title: message,
      message: '',
      confirmText: $localize`:@@OK:OK`,
      cancelText: '',
      type: DialogContentType.DONE,
    };

    return this.ShowMessage(data);
  }

  public ShowSuccessAction() {
    const message = $localize`:@@Message.SuccessAction:Thao tác thành công.`;
    return this.ShowSuccess(message);
  }
  public ShowInvalidAction() {
    const message = $localize`:@@Message.InvalidAction:Thao tác không hợp lệ.`;
    return this.ShowSuccess(message);
  }
  public ShowAccessDenied() {
    const message = $localize`:@@Message.AccessDenied:Bạn không có quyền truy cập.`;
    return this.ShowSuccess(message);
  }
  public ShowInvalidSession() {
    const message = $localize`:@@Message.InvalidSession:Phiên đăng nhập đã kết thúc. Vui lòng đăng nhập lại.`;
    return this.ShowWarn(message);
  }
}
