import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule, NgIf, NgSwitch } from '@angular/common';
import { DialogCloseResult, DialogContentData, DialogContentType } from './dialog.model';

@Component({
  selector: 'app-modal-content',
  standalone: true,
  imports: [NgIf, CommonModule, NgSwitch],
  template: `
		<div class="modal-body">
      <div class="row">
        <div style="display: flex; justify-content: center;">
          <span [ngSwitch]="data.type">
            <i *ngSwitchCase="DialogContentType.CONFIRM" class="bi bi-question-circle text-secondary" style="font-size: 64px; width: 64px;"></i>
            <i *ngSwitchCase="DialogContentType.DONE" class="bi bi-check-circle text-success" style="font-size: 64px; width: 64px;"></i>
            <i *ngSwitchCase="DialogContentType.ERROR" class="bi bi-x-circle text-danger" style="font-size: 64px; width: 64px;"></i>
            <i *ngSwitchCase="DialogContentType.WARN" class="bi bi-exclamation-circle text-warning" style="font-size: 64px; width: 64px;"></i>
            <i *ngSwitchDefault class="bi bi-info-circle text-secondary" style="font-size: 64px; width: 64px;"></i>
          </span>
        </div>

        <div style="display: flex; justify-content: center;">
          <h1 *ngIf="data.title" class="text-center">{{data.title}}</h1>
        </div>

        <div style="display: flex; justify-content: center; padding-top: 10px;">
          <p *ngIf="data.message" [innerHTML]="data.message"></p>
        </div>

        <div style="display: flex; justify-content: center; padding-top: 10px;">
          <button
            type="button"
            class="btn btn-secondary"
            style="margin-right: 6px; min-width: 60px;"
            (click)="activeModal.close(DialogResult.NO)"
            *ngIf="data.cancelText">
            {{data.cancelText}}
          </button>
          <button
            type="button"
            class="btn btn-primary"
            style="margin-right: 6px; min-width: 60px;"
            (click)="activeModal.close(DialogResult.YES)"
            *ngIf="data.confirmText">
            {{data.confirmText}}
          </button>
        </div>
      </div>

		</div>
	`,
})
export class DialogContentComponent {
  @Input() data!: DialogContentData;

  DialogContentType = DialogContentType;
  DialogResult = DialogCloseResult;
  constructor(public activeModal: NgbActiveModal) { }
}
